import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-xs-12 col-sm-12 col-md-3 q-pa-md" }
const _hoisted_2 = {
  class: "col-xs-12 col-sm-12 col-md-6 q-pa-md",
  style: {"background":"#f5f5f5"}
}
const _hoisted_3 = { class: "col-xs-12 col-sm-12 col-md-3 q-pa-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserCard = _resolveComponent("UserCard")!
  const _component_Plantao = _resolveComponent("Plantao")!
  const _component_RegimentoInternoCard = _resolveComponent("RegimentoInternoCard")!
  const _component_Feed = _resolveComponent("Feed")!
  const _component_CursosCard = _resolveComponent("CursosCard")!
  const _component_DisciplinasCard = _resolveComponent("DisciplinasCard")!
  const _component_TurmaCard = _resolveComponent("TurmaCard")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "row" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_UserCard, {
          user: _ctx.user,
          url: _ctx.urlImg
        }, null, 8, ["user", "url"]),
        _createVNode(_component_Plantao, {
          user: _ctx.user,
          plantoes: _ctx.plantoes
        }, null, 8, ["user", "plantoes"]),
        _createVNode(_component_RegimentoInternoCard, { regimentoInternos: _ctx.regimentoInternos }, null, 8, ["regimentoInternos"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Feed, {
          notificacoes: _ctx.notificacoes,
          "notificacoes-fixadas": _ctx.notificacoesFixadas,
          onAtualizarNotificacoes: _ctx.getNotificacoes,
          ref: "feed"
        }, null, 8, ["notificacoes", "notificacoes-fixadas", "onAtualizarNotificacoes"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_CursosCard, {
          user: _ctx.user,
          cursos: _ctx.cursos
        }, null, 8, ["user", "cursos"]),
        _createVNode(_component_DisciplinasCard, { disciplinas: _ctx.disciplinas }, null, 8, ["disciplinas"]),
        _createVNode(_component_TurmaCard, { user: _ctx.user }, null, 8, ["user"])
      ])
    ]),
    _: 1
  }))
}