
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    user: { type: Object },
    regimentoInternos: { type: Array },
  },
  setup(props) {
    const url = ref<string>(process.env.VUE_APP_STORAGE_BASE);
    return { url };
  },
});
