
import router from "@/router";
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: { notificacao: { type: Object } },
  setup(props) {
    const url = ref<string>(process.env.VUE_APP_STORAGE_BASE);
    const goToAvaliacao = (avaliacaoId: string): void => {
      router.push(`/minhas-avaliacoes/${avaliacaoId}`);
    };
    return { url, goToAvaliacao };
  },
});
