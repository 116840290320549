
import { defineComponent } from "vue";
import { requiredField } from "@/util/form-rules";
import FormLoading from "../../layout/FormLoading.vue";
import useForm from "@/modules/useForm";
import PlantaoDuvida from "@/interfaces/PlantaoDuvida";

interface Form {
  url: string;
}

const baseForm: Form = {
  url: "",
};

export default defineComponent({
  components: { FormLoading },
  setup(props, { emit }) {
    const { show, form, row, loading, submit, status, afterSubmit } = useForm(
      "plantao-duvidas/url",
      "plantão ao Vivo",
      "o",
      baseForm,
      emit,
      "plantao-duvidas"
    );

    const open = (data: PlantaoDuvida | null): void => {
      if (data) {
        row.value = JSON.parse(JSON.stringify(data));
        if (row.value?.id) {
          form.value = {
            url: row.value.url,
          };
        } else {
          form.value = JSON.parse(JSON.stringify(baseForm));
        }
      }
      show.value = true;
    };

    return {
      show,
      open,
      form,
      row,
      requiredField,
      loading,
      submit,
      status,
      afterSubmit,
    };
  },
});
