
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    user: { type: Object },
    url: { type: String },
  },
  setup(props) {
    const formatData = (data: string): string => {
      return `${data.substr(8, 2)}/${data.substr(5, 2)}/${data.substr(0, 4)}`;
    };

    return { formatData };
  },
});
