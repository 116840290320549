
import router from "@/router";
import { defineComponent } from "vue";

export default defineComponent({
  props: { notificacao: { type: Object } },
  setup(props) {
    const colorStatus = {
      Aberta: "orange",
      Fechada: "red",
      Respondida: "green",
    };
    const iconStatus = {
      Aberta: "question_mark",
      Fechada: "done",
      Respondida: "question_answer",
    };

    const goToDuvida = (aulaId: string, disciplinaId: string): void => {
      console.log(aulaId);
      router.push({
        name: "ver-aula",
        params: { id: disciplinaId, aulaRouterId: aulaId },
      });
    };

    return { goToDuvida, colorStatus, iconStatus };
  },
});
