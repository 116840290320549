
import router from "@/router";
import { defineComponent } from "vue";

export default defineComponent({
  props: { notificacao: { type: Object } },
  setup(props) {
    const goToAula = (aulaId: string, disciplinaId: string): void => {
      router.push({
        name: "ver-aula",
        params: { id: disciplinaId, aulaRouterId: aulaId },
      });
    };
    return { goToAula };
  },
});
