
import Curso from "@/interfaces/Curso";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    user: { type: Object },
    cursos: { type: Array },
  },
  setup(props) {
    const porcetangem = (curso: Curso): number[] => {
      let aulas = 0;
      let assistidas = 0;
      let porcentagem = 0;
      let decimal = 0;
      curso.disciplinas.forEach((disciplina) => {
        disciplina.assuntos.forEach((assunto) => {
          assunto.aulas.forEach((aula) => {
            if (aula.aula_assistida) {
              assistidas++;
            }
            aulas++;
          });
        });
      });
      porcentagem = (assistidas * 100) / aulas;
      decimal = porcentagem / 100;
      return [decimal, porcentagem];
    };
    return { porcetangem };
  },
});
