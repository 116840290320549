
import router from "@/router";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    user: { type: Object },
  },
  setup() {
    const goToAula = (disciplinaId: string): void => {
      router.push({
        name: "ver-aula",
        params: { id: disciplinaId },
      });
    };
    return { goToAula };
  },
});
