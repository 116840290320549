
import { defineComponent, ref } from "vue";
import PlantaoDuvidaHomeForm from "@/components/admin/plantao-duvidas/PlantaoDuvidaHomeForm.vue";
import PlantaoDuvida from "@/interfaces/PlantaoDuvida";

export default defineComponent({
  components: { PlantaoDuvidaHomeForm },
  props: {
    plantoes: { type: Array },
    user: { type: Object },
  },
  setup(props) {
    const modalForm = ref();

    const openModalForm = (row: PlantaoDuvida): void => {
      modalForm.value.open(row);
    };

    return { modalForm, openModalForm };
  },
});
