
import BaseService from "@/services/admin/BaseService";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: { notificacao: { type: Object } },
  setup(props) {
    const $q = useQuasar();
    const loading = ref<boolean>(false);
    const url = ref<string>(process.env.VUE_APP_STORAGE_BASE);

    const marcarPostagem = (postagem: any, status: boolean): void => {
      loading.value = true;
      BaseService.list(`/aluno/marcar-postagem/${postagem.id}`)
        .then(() => {
          postagem.visualizado = !postagem.visualizado;
          if (status) {
            showNotify({
              type: "positive",
              message: "Postagem marcada como lida",
            });
          } else {
            showNotify({
              type: "positive",
              message: "Postagem marcada como não lida",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    return { loading, marcarPostagem, showNotify, url };
  },
});
