
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: { notificacao: { type: Object } },
  setup(props) {
    const url = ref<string>(process.env.VUE_APP_STORAGE_BASE);
    return { url };
  },
});
